// JS Goes here - ES6 supported
import "./css/main.scss";
import Swal from 'sweetalert2'
import 'particles.js'


/* contact forms handling */
/**
 * look for forms on page
 * prevent default behavior
 * make a post request to nodya centralized form managment
 * handle UI feedback on error and success
 * disable form for 2 days if form was submited succesfully
 */
function initMobileMenu(){
  let toggle = document.getElementById('menuToggle')
  let menu =document.getElementById('mainMenu')
  toggle.addEventListener('click', (e) => {
    if(menu.classList.contains('closed')) menu.classList.remove('closed')
    else menu.classList.add('closed')
  })
}
function highlightRequiredInputs(){
    for(let input of document.querySelectorAll('input[required]')){
        input.classList.add('border-yellow-400')

        let requiredInputs = function(e){
            input.classList.remove('border-yellow-400')
            input.removeEventListener('change', requiredInputs)
        }
        input.addEventListener('change', requiredInputs)
    }
}
function handleSubmission(e, id){
    e.preventDefault()

    var form = getForm(id)

    if(!form.checkValidity()){
        Swal.fire({
            icon:"warning",
            title: 'Erreur',
            text: "Veuillez remplir les champs requis",
            confirmButtonText: 'Ok',
        })
        
        highlightRequiredInputs()
        return
    }

    submitForm(form, id)
}
function getSubmitButton(id){
  return document.querySelector('#submitButton-' + id)
}

function submitForm(form, id){
    // prepare request
    var req = new XMLHttpRequest();
    let url = form.getAttribute('action')

    let submitButton = getSubmitButton(id)

    // prepare form data
    var formData = new FormData(form)
    var object = {};
    formData.forEach(function(value, key){
        object[key] = value;
    });
    var json = JSON.stringify(object);

    req.open("POST", url)
    req.setRequestHeader('Content-Type', form.getAttribute('enctype'))

    // set loading text
    if(submitButton != null){
      submitButton.value = 'Envoie en cours...'
      disableForms()
    }
    let failedTimeout = setTimeout(()=>{
        req.abort()
        Swal.fire({
            icon:"error",
            title: 'Une erreur est survenue',
            text: "Le serveur a mis trop de temps à répondre, et nous avons annulé l'envoie du formulaire... Veuillez vérifier votre connexion à internet, ou réessayez plus tard...",
            confirmButtonText: 'Ok',
        })
        if(submitButton != null){
          submitButton.value = 'Réessayer'
          enableFormElmt(submitButton)
          for(let input of getInputs()){
            enableFormElmt(input)
          }
        }
    }, 15000)

    // error handling
    req.onError = function(e) {
        clearTimeout(failedTimeout)
        // console.log('error')
        if(submitButton != null){
            submitButton.value = 'Réessayer'
            submitButton.disabled = false
            submitButton.classList.add('text-blue-800')
            submitButton.classList.add('cursor-pointer')
            submitButton.classList.remove('text-gray-400')
            submitButton.classList.remove('cursor-not-allowed')
        }
        Swal.fire({
            icon:"error",
            title: 'Une erreur est survenue',
            text: "Nous avons rencontré une erreur sur le réseau... Merci de réessayer plus tard, ou de nous contacter par un autre moyen (mail, téléphone, linkedin)",
            confirmButtonText: 'Ok',
        })
    }

    // success handling
    req.onload = function(e) {
        clearTimeout(failedTimeout)
        localStorage.setItem('ndy_hasSubmited', Date.now())
        Swal.fire({
            icon:"success",
            title: 'Merci.',
            text: "Votre demande de contact a bien été envoyée. Nous reviendrons vers vous très prochainement.",
            confirmButtonText: 'Ok',
        })
        disableForms()
        getSubmitButton(id).value = "Votre demande de contact a bien été envoyée."
    }

    req.send(json);
}

function initForms(){
    let hasSubmited = localStorage.getItem('ndy_hasSubmited')
  
    // Check if there are multiple contact forms on the same page
    let contactForms = document.getElementsByClassName('contact-form')

    Array.from(contactForms).forEach((f, index) => {
      let id = index + 1
      let submitButton = getSubmitButton(id)

      if(Date.now() - hasSubmited < 1000 * 60 * 60 * 24 * 1){ // if last submission was 1 days ago or less
        if(submitButton != null) {
          submitButton.value = "Votre demande de contact a bien été envoyée."
          disableForms()
        }
        else return
      }

      if(submitButton != null){
          submitButton.addEventListener('click', (e) => handleSubmission(e, id))
      }
    })
}

function disableFormElmt(elmt){
  elmt.disabled = true
  elmt.classList.remove('text-blue-800')
  elmt.classList.remove('cursor-pointer')
  elmt.classList.add('text-gray-400')
  elmt.classList.add('cursor-not-allowed')
  elmt.classList.add('bg-gray-200')
}
function enableFormElmt(elmt){
  elmt.disabled = false
  elmt.classList.add('text-blue-800')
  elmt.classList.add('cursor-pointer')
  elmt.classList.remove('text-gray-400')
  elmt.classList.remove('cursor-not-allowed')
  elmt.classList.remove('bg-gray-200')
}
function getForm(id){
  return document.getElementById('contactForm-' + id)
}
function getInputs(id){
  return getForm(id).querySelectorAll("input")
}
function disableForms(){
  let submitButtons = document.getElementsByClassName("submit-button")
  Array.from(submitButtons).forEach((b, index) => {
    let id = index + 1
    disableFormElmt(getSubmitButton(id))
    getSubmitButton(id).classList.add('text-sm')
    getSubmitButton(id).classList.remove('text-lg')
    for(let input of getInputs(id)){
      disableFormElmt(input)
    }
    return
  })
  
}


/* no cookies notice handling */
/**
 * show a cookie notice if the user visits for first time today
 * on ok click, save current timestamp to localstorage
 * dont show notice again after this for one day
 */
function okCookieBtn(){
  return document.getElementById('ndy-okCookies')
}
function cookieNotice(){
  return document.getElementById('ndy-cookieNotice')
}
function hideNoCookieNotice(){
  if(!cookieNotice().classList.contains('translate-y-full'))
    cookieNotice().classList.add('translate-y-full')
}
function showNoCookieNotice(){
  if(cookieNotice().classList.contains('translate-y-full'))
    cookieNotice().classList.remove('translate-y-full')
}
var handleNotice = function(e){
  localStorage.setItem('ndy_hasSeenNotice', Date.now())
  hideNoCookieNotice()
}
function noCookieNotice(){
  let hasSeenNotice = localStorage.getItem('ndy_hasSeenNotice')
  
  if(Date.now() - hasSeenNotice > 1000 * 60 * 60 * 24 * 1){ // if last seen was more than one day
    showNoCookieNotice()
  }
  
  okCookieBtn().addEventListener('click', handleNotice)
}


var doubleClickTimeout = null
var handleVideoClick = (videoElmt) => {
  videoElmt.volume = 0
  // on simple click, play/pause
  if(doubleClickTimeout == null){
    doubleClickTimeout = setTimeout(()=> {
      videoElmt.paused ? videoElmt.play() : videoElmt.pause();
      doubleClickTimeout = null;
    }, 250)
  }
  // on doubleClick, requestFullscreen
  else{
    clearTimeout(doubleClickTimeout)
    doubleClickTimeout = null;
    videoElmt.requestFullscreen()
  }
}
document.handleVideoClick = handleVideoClick

/**
 * checks wehter el is in viewport. return bool
 * @param {*} el 
 * @returns {boolean}
 */
function isElementInViewport (el) {
  // Special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
      el = el[0];
  }

  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

function initAnimations(){
  // animation management
  var animatedElmts = document.querySelectorAll('.animation')
  var playedElmts = []

  const appearVisible = ()=>{
    var visibleElmts = []
  
    for(let elmt of animatedElmts){
      if(isElementInViewport(elmt)) visibleElmts.push(elmt)
    }
  
    for(let elmt of visibleElmts){
      if(playedElmts.includes(elmt) === false){
        playedElmts.push(elmt)
        elmt.classList.add('play')
      }
    }
  }
  document.addEventListener("scroll", ()=>{
    appearVisible()
  })
  appearVisible()
}

function initFlashyButtons(){
  // flashy buttons
  var flashyButtons = document.querySelectorAll('.flashy-button')
  // set stroke dashArray
  for(let btn of flashyButtons){
    let svg = btn.querySelector('svg')
    svg.style.strokeDasharray = `${btn.clientHeight}px ${btn.clientWidth-10}px`
  }
}

/**
 * STATIC variables to loop through available sections
 * TODO make this generic from DOM, not hard-coded here
 */
var SECTION_CAROUSEL_DURATION = 3000
var argumentSectionData = {
  activeSection: 'reporting',
  sections: ['reporting','costs','performances','anticipate','quality']
}

function onSectionButtonClick(section){
  switchSection(section)
  stopCarouselLoop()
  setTimeout(()=>{startCarouselLoop()}, 7000)
  argumentSectionData.activeSection = section
}
window.onSectionButtonClick = onSectionButtonClick

/**
 * shows section by name, and hides others
 * also changes related buttons style
 * @param {*} section 
 */
function switchSection(section) {
  // Class to display section and highlight button on chart
  var selectedButtonElmt = document.getElementById(section + "-button")
  var selectedSectionElmt = document.getElementById(section + "-section")
  selectedButtonElmt.classList.add("button-highlight")
  selectedSectionElmt.classList.remove('hidden')

  // Class to display section and highlight button on chart
  var foundSectionNames = argumentSectionData.sections.filter((s) =>  s != section)

  foundSectionNames.forEach((sectionName) => {
    var buttonElmt = document.getElementById(sectionName + '-button')
    buttonElmt.classList.remove("button-highlight")

    var sectionElmt = document.getElementById(sectionName + '-section')
    sectionElmt.classList.add("hidden")
  })
}
window.switchSection = switchSection

/**
 * utility function that returns section name that is next to currently active section
 * only works forward
 * @param {*} activeSection 
 * @returns 
 */
function getNextSection(activeSection){
    // finding section after current active section
    var currentSectionId = argumentSectionData.sections.indexOf(activeSection)
    var nextSection = ''
    if(currentSectionId + 1 == argumentSectionData.sections.length){
      nextSection = argumentSectionData.sections[0]
    }
    else{
      nextSection = argumentSectionData.sections[currentSectionId + 1]
    }
    return nextSection
}

var carouselInterval

function startCarouselLoop(){
  carouselInterval = setInterval(()=>{
    var nextSection = getNextSection(argumentSectionData.activeSection)
    switchSection(nextSection)
    argumentSectionData.activeSection = nextSection
  }, SECTION_CAROUSEL_DURATION)
}
window.startCarouselLoop = startCarouselLoop

function stopCarouselLoop(){
  clearInterval(carouselInterval)
}
window.stopCarouselLoop = stopCarouselLoop

/**
 * inits caroussel loop
 */
function initSectionsCarousel(){
  switchSection(argumentSectionData.activeSection)
  startCarouselLoop()
}

// make looper
// cela devrait parcourir les differentes sections dispos dans la page et les affichee l'une apres l'autre
// si l'utilisateur clique sur une section, on l'affiche (deja fait), et cela doit mettre en pause la boucle de parcours pendant un certain temps. Apres ce temps de pause, la boucle doit reprendre depuis l'endroit affiché
// ca change de hauteur c'est moche


/* bootstrap */
document.addEventListener('DOMContentLoaded', ()=>{
  setTimeout(()=>{initAnimations()},500)
  setTimeout(()=>{initFlashyButtons()},5)
  initForms()
  initMobileMenu()
  noCookieNotice()
  initSectionsCarousel()
})